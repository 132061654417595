html,
body {
  background-color: black;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  overscroll-behavior-y: none;
  width: 100vw;
}

h1,
h2,
h3,
h4 {
  font-family: 'Basier Circle', sans-serif;
  font-weight: 400;
  margin: 0.2em 0em;
  margin-block-end: 0em;
  margin-block-start: 0em;
}

/* Autofill Overrides START */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

/* Autofill Overrides END */

.error-message {
  font-family: 'Basier Square', sans-serif;
  font-size: 14px;
  color: rgba(var(--primary-colour), 1);
}

p {
  font-family: 'Basier Square', sans-serif;
  color: rgba(var(--system-colour), 1);
  font-size: 13px;
  line-height: 1.5em;
  margin: 0px;
  font-weight: 400;
}

a {
  color: rgba(var(--secondary-colour), 1);
  text-decoration: none;
  font-weight: 500;
}

a:any-link {
  text-decoration: none !important;
}

h1 {
  color: rgba(var(--primary-colour), 1);
  font-size: 24px;
  font-weight: 500;
}

h2 {
  color: rgba(var(--system-colour), 1);
  font-size: 14px;
}

h3 {
  color: rgba(var(--primary-colour), 1);
  font-size: 14px;
}

h4 {
  color: rgba(var(--secondary-colour), 1);
  font-size: 12px;
}

body {
  margin: 0px;
  height: 100vh;
  height: -webkit-fill-available;
  /* overflow-y: hidden; */
}

div#wrapper {
  background-color: rgba(var(--background-colour), 1);
  position: absolute;
  width: 100vw;
  left: 0;
  height: calc(100% - 0px);
  margin-top: 0px;
  border-radius: 32px 32px 0px 0px;
  margin: 0px;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 8px;
  padding-bottom: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

div#wrapper .content {
  height: 100dvh;
  width: 100%;
  border-radius: 24px 24px 0px 0px;
  flex-basis: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0px;
  overscroll-behavior-y: none;
}

div#wrapper .content::-webkit-scrollbar {
  display: none;
}

.disable-scrollbars::-webkit-scrollbar {
  display: none;
  /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.appLandingContainer {
  position: relative;
  background: rgba(var(--background-colour), 1);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.desktopContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.artworkHeroContainer {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.artworkHero {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
  /* border-radius: 24px; */
}

.artworkHero img {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

section>*:first-child {
  margin-top: 0px;
}

section>*:last-child {
  margin-bottom: 0px;
}

.eventInformation {
  width: 100%;
}

hr {
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin: 16px 8px;
  border-color: rgba(var(--primary-colour), 0.2);
}

.widthInner {
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;
}

h1.widthInner {
  font-size: 20px;
  font-weight: 400;
}

.eventTitleContainer {
  width: 100%;
  margin: 24px 0px;
  justify-content: center;
  padding: 0px 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: 0.2s linear;
  text-align: left;
}

.eventTitleContainer h1 {
  color: rgba(var(--system-colour), 1);
  font-size: 32px;
}

.eventTitleContainer>* {
  margin: initial;
}

.organiserLogo {
  height: 40px;
  width: 40px;
  margin-right: 16px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 12px;
  overflow: hidden;
}

.organiserLogo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.organiserName {
  width: 100%;
}

.eventInformationCard.broker {
  background-color: rgba(0, 0, 0, 1);
  gap: 16px;
}

.brokerLogo {
  height: 16px;
}

.brokerLogo img {
  width: auto;
  max-width: 32px;
  height: 16px;
}

.eventInformationCard.broker .brokerText {
  display: flex;
  flex-direction: row;
  gap: 6px;
  flex-basis: 100%;
}

.eventInformationCard.broker .brokerText p {
  color: rgba(197, 197, 197, 1);
}

.eventInformationCard.broker .brokerName {
  color: rgba(255, 255, 255, 1) !important;
}

.eventInformationCard.broker .brokerInfoIcon {
  display: none;
  margin-left: auto;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.eventInformationCard.broker .brokerInfoIcon svg {
  fill: rgba(197, 197, 197, 1);
  transition: fill 0.2s linear;
}

.eventInformationCard.broker .brokerInfoIcon:hover svg {
  fill: rgba(255, 255, 255, 1);
}

.eventParameters,
.eventOrganiser {
  display: flex;
  gap: 8px;
  min-width: 300px;
  flex-wrap: wrap;
}

.eventOrganiser .eventInformationCard:first-child {
  margin-top: 16px;
}

.eventInformationCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  margin: 0px;
  padding: 16px;
  border-radius: 24px;
  overflow: hidden;
  background: rgba(var(--primary-colour), 0.1);
  flex-grow: 1;
  width: 100%;
}

.eventInformationCard h4 {
  color: rgba(var(--primary-colour), 1);
}

.eventInformationIcon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#eventBookingFeeRefundDisclaimer .eventInformationIcon {
  width: 16px;
  height: 16px;
}

.eventInformationIcon svg {
  width: 16px;
  height: 16px;
  fill: rgba(var(--primary-colour), 1);
}

.eventInformationText {
  width: 100%;
  margin-left: 12px;
}

.customEventInformationContainer {
  display: flex;
  gap: 8px;
  min-width: 300px;
  flex-wrap: wrap;
}

.customEventInformationContainer .eventInformationCard {
  width: fit-content;
}

.customEventInformationContainer .eventInformationIcon img {
  width: 16px;
  height: 16px !important;
}

.eventDescriptionContainer p {
  margin-top: 15px;
  font-weight: 400;
  font-size: 13px;
}

.ctaContainer::before {
  content: '';
  position: absolute;
  width: calc(100% + 32px);
  height: 40px;
  top: -40px;
  left: -16px;
  background-image: linear-gradient(rgba(var(--background-colour), 0), rgba(var(--background-colour), 0.8));
}

.ctaContainer {
  position: -webkit-sticky;
  position: sticky;
  margin-top: 40px;
  bottom: 0;
  padding-top: 8px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: row;
  background-color: rgba(var(--background-colour), 0.8);
  align-items: center;
}

.transparentBg {
  background-color: transparent !important;
}

.ctaContainer .ctaText {
  flex-grow: 0;
  display: none;
}

.ctaContainer .ctaText h3 {
  color: rgba(var(--system-colour), 1);
}

.ctaContainer .ctaText p {
  color: rgba(var(--secondary-colour), 1);
  line-height: 0.5em;
  margin-top: 0.5em;
}

.ctaContainer .ctaButton {
  margin-left: 0px;
  flex-grow: 1;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s linear;
}

.ctaContainer .ctaButton:active {
  opacity: 0.8;
}

.ctaContainer .ctaButton h3 {
  font-family: 'Basier Circle', sans-serif;
  font-weight: 600;
  vertical-align: middle;
  font-size: 14px;
  text-decoration: none;
}

@media screen and (min-width: 700px) {

  .artworkHeroContainer,
  .eventTitle,
  .eventDetails {
    max-width: 700px;
    margin: auto;
  }

  .appCheckoutContainer {
    max-width: 800px;
    margin: auto;
  }
}

/* Checkout Flow Container */

.appCheckout {
  visibility: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: rgba(var(--background-colour), 1);
  z-index: 10;
  transition: all 0.2s linear;
  transition: display 0.2s linear;
}

.appCheckout.active {
  visibility: visible;
  opacity: 1;
}

.appCheckoutContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 0.2s linear;
  transition-delay: 0.5s;
  opacity: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.appCheckout.active .appCheckoutContainer {
  transform: translateY(0%);
  opacity: 1;
}

.appcheckout .eventOverview {
  margin-top: auto;
  margin-bottom: 20px;
}

.appCheckoutCard {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overscroll-behavior-x: none;
  overflow-y: scroll;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  flex-basis: 100%;
  border-radius: 16px 16px 0px 0px;
  background-color: rgba(var(--background-colour), 1);
  box-sizing: border-box;
  overscroll-behavior-y: none;
}

.appCheckoutCardTopLine {
  margin: 8px;
  margin-bottom: 16px;
}

.appCheckoutCard::-webkit-scrollbar {
  display: none;
}

.appCheckoutStep {
  display: none;
  width: 100%;
}

.appCheckoutStep.active {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.appCheckoutStepTitle {
  color: rgba(var(--system-colour), 1);
}

.appCheckoutStepHeadingWithTimer {
  margin: 16px 0px;
  display: flex;
  justify-content: space-between;
}

.appCheckoutStepHeading {
  text-align: left;
  margin: 0px 8px;
  padding-bottom: 24px;
}

p.appCheckoutStepDescription {
  font-size: 14px;
  color: rgba(var(--primary-colour), 1);
}

div.appCheckoutOrderTotal {
  min-height: 69px;
}

p.appCheckoutOrderTotalTitle {
  font-size: 14px;
  color: rgba(var(--primary-colour), 1);
  text-align: center;
}

p.appCheckoutOrderTotalAmount {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}

.appCheckoutStepInfoField {
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.appCheckoutStepInfoTitle {
  font-size: 16px;
  line-height: 1em;
}

.appCheckoutStepInfoDescription {
  font-size: 14px;
  color: rgba(var(--secondary-colour), 1);
}

.appCheckoutStepInfoDescription a {
  color: rgba(var(--system-colour), 1);
  text-decoration: none;
}

/* Step 2: Ticket Selection */

.checkoutTicketType {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border: 2px transparent solid;
  background-color: rgba(var(--secondary-colour), 0.1);
  border-radius: 24px;
  align-items: center;
  transition: all 0.2s linear;
  overflow: hidden;
}

.checkoutTicketType:first-child {
  margin-top: 0px;
}

.checkoutTicketType.hidden {
  display: none;
}

.checkoutTicketType .ticketInformation {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  align-items: center;
}

.checkoutTicketType .ticketDescription {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  gap: 8px;
}

.checkoutTicketType .ticketNote {
  width: 100%;
  margin-top: 10px;
}

.checkoutTicketType .ticketName {
  flex-basis: 100%;
}

.checkoutTicketType .ticketPrice {
  flex-shrink: 0;
  color: rgba(var(--system-colour), 1);
}

.checkoutTicketType .ticketPrice>.ticketCurrency {
  font-size: 13px;
}

.checkoutTicketType .ticketPrice>.ticketFees {
  color: rgba(var(--secondary-colour), 1);
  margin-left: 5px;
}

.checkoutTicketType .ticketPrice>.minor {
  font-size: 13px;
  font-weight: 100;
}

.checkoutTicketType .ticketIcon {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 9px;
  height: 15px;
  overflow: hidden;
  transition: all 0.2s linear;
}

.checkoutTicketType.selected .ticketIcon {
  width: 15px;
}

.checkoutTicketType .ticketIcon svg {
  position: absolute;
  top: 0;
  left: 0;
  fill: rgba(var(--secondary-colour), 1);
  width: 15px;
  left: 0px;
  transition: all 0.2s linear;
}

.checkoutTicketType .ticketIcon svg#iconProceed,
.checkoutTicketType.selected .ticketIcon svg#iconCancel {
  opacity: 1;
}

.checkoutTicketType .ticketIcon svg#iconCancel {
  opacity: 0;
}

/* Step 3: Select Ticket Quantity */

.ticketQuantitySelector {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

.ticketQuantity {
  color: rgba(var(--secondary-colour), 1);
  text-align: center;
  margin: 0px 15px;
}

.ticketQuantityButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ticketQuantitySelector .ticketQuantityButton {
  font-family: 'Basier Square', sans-serif;
  color: rgba(var(--secondary-colour), 1);
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--secondary-colour), 0.1);
  border: 2px transparent solid;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
}

.ticketQuantitySelector svg {
  width: 16px !important;
  height: 16px !important;
  fill: rgba(var(--secondary-colour), 1);
  transition: all 0.2s linear;
}

.ticketQuantityButton:hover svg {
  fill: rgba(var(--system-colour), 1);
}

.ticketQuantitySelector .ticketQuantityButton:hover {
  color: rgba(var(--system-colour), 1);
  background-color: rgba(var(--secondary-colour), 0.2);
  border: 2px rgba(var(--secondary-colour), 1) solid;
  transition: all 0.3s linear;
}

.ticketQuantitySelector .ticketQuantityButton.selected {
  color: rgba(var(--system-colour), 1);
  background-color: rgba(var(--secondary-colour), 0.2);
  border: 2px rgba(var(--system-colour), 1) solid;
}

.ticketQuantitySelector .ticketQuantityButton.disabled {
  cursor: not-allowed;
  background-color: rgba(var(--secondary-colour), 0.05);
  border: 2px transparent solid;
}

.ticketOrderSummaryContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.ticketOrderSummaryRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ticketOrderSummaryRow .ticketOrderSummaryRowTitle,
.ticketOrderSummaryRow .ticketOrderSummaryRowFigure {
  color: rgba(var(--primary-colour), 1);
}

.ticketOrderSummaryRow.total .ticketOrderSummaryRowTitle,
.ticketOrderSummaryRow.total .ticketOrderSummaryRowFigure {
  font-size: 14px;
  color: rgba(var(--system-colour), 1);
}

.ticketQuantityDisclaimerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.ticketQuantityDisclaimerButton {
  margin-right: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

p.ticketQuantityDisclaimer {
  width: 100%;
  font-size: 12px;
  line-height: 14px;
}

.bulletCheck {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.bulletCheck .bullet {
  fill: rgba(var(--secondary-colour), 1);
}

.bulletCheck .bulletChecked {
  fill: rgba(var(--system-colour), 1);
  opacity: 0;
  transform-origin: center;
  transform: scale(0);
  transition: all 0.2s linear;
}

.bulletCheck.checked .bulletChecked {
  opacity: 1;
  transform: scale(1);
}

.ticketQuantityDisclaimer {
  color: rgba(var(--secondary-colour), 1);
  font-size: 13px;
  text-align: left;
  flex-basis: 100%;
}

.ticketQuantityDisclaimer a {
  color: rgba(var(--system-colour), 1);
  text-decoration: none;
}

.buttonProceed {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  overflow: hidden;
  border-radius: 16px;
  margin: 0px 16px;
  opacity: 1;
  cursor: pointer;
  border: solid 2px transparent;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  background: linear-gradient(rgba(var(--secondary-colour), 1), rgba(var(--secondary-colour), 1)) padding-box,
    linear-gradient(-30deg,
      rgba(var(--system-colour), 0.3),
      rgba(var(--system-colour), 0.05) 40%,
      rgba(var(--system-colour), 0.05) 90%,
      rgba(var(--system-colour), 1)) border-box,
    linear-gradient(rgba(var(--secondary-colour), 1), rgba(var(--secondary-colour), 1)) border-box;
  transition: all 0.3s linear;
}

.buttonProceed * {
  font-weight: 500;
  text-align: center;
  font-size: 15px;
  /* color: rgba(var(--secondary-colour), 1); */
  color: rgba(var(--background-colour), 1);
  box-sizing: border-box;
  padding: 16px;
}

.buttonProceed:not(.blocked):hover {
  color: rgba(var(--secondary-colour), 1);
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.25);
}

.buttonProceed.blocked {
  opacity: 0.7;
  pointer-events: none;
  box-shadow: 0px 8px 0px rgba(0, 0, 0, 0);
  border: solid 2px rgba(255, 255, 255, 0);
  background: linear-gradient(rgba(var(--secondary-colour), 0.1), rgba(var(--secondary-colour), 0.1)) padding-box,
    linear-gradient(rgba(var(--background-colour), 1), rgba(var(--background-colour), 1)) padding-box,
    linear-gradient(-30deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 0)) border-box;
}

.buttonProceed:not(.blocked):hover {
  color: rgba(var(--secondary-colour), 1);
}

.buttonProceed.blocked {
  pointer-events: none;
  box-shadow: 0px 8px 0px rgba(0, 0, 0, 0);
  border: solid 2px rgba(255, 255, 255, 0);
  background: linear-gradient(rgba(var(--secondary-colour), 0.1), rgba(var(--secondary-colour), 0.1)) padding-box,
    linear-gradient(rgba(var(--background-colour), 1), rgba(var(--background-colour), 1)) padding-box,
    linear-gradient(-30deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 0)) border-box,
    linear-gradient(rgba(var(--secondary-colour), 0.1), rgba(var(--secondary-colour), 0.1)) border-box;
  cursor: pointer;
}

apple-pay-button {
  filter: blur(0px);
  margin-top: 20px;
  box-sizing: border-box;
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 10px;
  --apple-pay-button-padding: 10px 0px;
  transition: all 0.2s linear;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border: rgba(var(--secondary-colour), 0.1);
  border-radius: 50px;
  transition: all 0.2s linear;
}

::-webkit-scrollbar-thumb:hover {
  border: rgba(var(--secondary-colour), 1);
}

::-webkit-scrollbar-thumb:active {
  border: rgba(var(--system-colour), 1);
}

::-webkit-scrollbar-track {
  background: transparent;
  border: rgba(var(--secondary-colour), 0.1);
  border-radius: 53px;
}

::-webkit-scrollbar-track:hover {
  background: transparent;
}

::-webkit-scrollbar-track:active {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.ctaContainer .ctaButton {
  background: black;
}

.ctaContainer .ctaButton:active {
  opacity: 0.8;
}

.ctaContainer .ctaButton h3 {
  color: white;
  font-weight: 400;
}

.appCheckout {
  justify-content: flex-end;
}

.appCheckoutEventOverview {
  align-items: center;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 8px;
}

.appCheckoutEventOverview .appCheckoutEventOverviewImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 12px;
  overflow: hidden;
  margin-right: 16px;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  border: 2px solid rgba(var(--primary-colour), 1);
}

.appCheckoutEventOverview .appCheckoutEventOverviewImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.appCheckoutEventOverview .appCheckoutEventOverviewText {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.appCheckoutEventOverview .appCheckoutEventOverviewText h1 {
  color: rgba(var(--system-colour), 1);
  font-size: 14px;
}

.appCheckoutEventOverview .appCheckoutEventOverviewText h3 {
  font-size: 14px;
}

.appCheckoutEventOverview .appCheckoutEventOverviewText * {
  margin: 0px;
}

/* -- START Form Styling --*/

form {
  padding: 16px 0px;
  margin-block-end: 0px;
  transition: all 0.2s linear;
  opacity: 1;
  height: auto;
}

form.hidden {
  display: none;
  overflow-y: hidden;
}

form input::placeholder {
  font-family: 'Basier Circle', sans-serif;
  color: rgba(var(--secondary-colour), 1);
}

form input,
form input::placeholder {
  font-size: 0px;
  transition: all 0.2s linear;
}

form .form-row {
  position: relative;
  width: 100%;
}

form .form-row:not(:first-child) {
  position: relative;
  width: 100%;
  margin-top: 8px;
}

form .form-row:last-child {
  margin-bottom: 0px;
}

form .form-field {
  position: relative;
  border-radius: 16px;
  background-color: rgba(var(--secondary-colour), 0.1);
  box-sizing: border-box;
  transition: all 0.2s linear;
}

form .form-field input {
  font-size: 16px;
  font-family: 'Basier Circle', sans-serif;
  color: rgba(var(--system-colour), 1);
  background: transparent;
  border: none;
  padding: 16px;
  margin-top: 1.125rem;
  outline: none !important;
  box-sizing: border-box;
  width: 100%;
  z-index: 99;
}

form .form-field label {
  font-family: 'Basier Square', sans-serif;
  color: rgba(var(--secondary-colour), 1);
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  letter-spacing: 0px;
  cursor: text;
  pointer-events: none;
  font-size: 16px;
  transition: all 0.162s linear;
  transform-origin: top left;
}

form .form-field:focus-within label,
form .form-field.isNotEmpty label {
  margin-top: -0.875rem;
  font-size: 14px;
}

form .questionWrapper .form-field label {
  padding: 0;
}

form .form-row-double {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 700px) {
  form .form-row-double {
    flex-direction: column;
    width: 100%;
  }

  form .form-row-double .form-field {
    width: 100% !important;
    margin-top: 8px;
  }
}

input[type='password' i] {
  letter-spacing: 0.25em;
}

input[type='password' i]:autofill {
  letter-spacing: 0em;
}

form .form-row-double .form-field {
  width: calc(50% - 10px);
}

form .form-field:focus-within {
  background: rgba(var(--secondary-colour), 0.2);
}

form .form-field:focus-within input::placeholder {
  color: rgba(var(--secondary-colour), 0.75);
}

form .form-field:focus-within label {
  color: rgba(var(--secondary-colour), 1) !important;
}

fieldset {
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-block-start: 0px;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-end: 0px;
  min-inline-size: none;
  border: none;
}

/* END Form Styling */

form.formContactDetails {
  height: 100%;
  padding-top: 0px;
}

.blocked {
  cursor: not-allowed;
}

.checkoutAttendeeDetailsOption {
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0px;
  border: 2px transparent solid;
  background-color: rgba(var(--secondary-colour), 0.1);
  border-radius: 10px;
  transition: all 0.2s linear;
  cursor: pointer;
  overflow: hidden;
}

.checkoutAttendeeDetailsOption:last-child {
  margin-top: 0px;
}

.checkoutAttendeeDetailsOption h2 {
  color: rgba(var(--system-colour), 1);
  font-size: 14px;
}

.checkoutAttendeeDetailsOption:hover {
  background-color: rgba(var(--secondary-colour), 0.2);
  border: 2px rgba(var(--secondary-colour), 1) solid;
}

.checkoutAttendeeDetailsOption.selected {
  background-color: rgba(var(--secondary-colour), 0.2);
  border: 2px rgba(var(--system-colour), 1) solid;
}

.checkoutAttendeeDetailsOption.hidden {
  display: none;
}

.checkoutAttendeeDetailsOption .checkoutAttendeeDetailsOptionText {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  align-items: flex-start;
  margin-left: 20px;
}

#attendeeDetailsAutomatic {
  margin-bottom: 0px;
}

.attendeeProfileContainer {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  background: rgba(var(--secondary-colour), 0.1);
  transition: all 0.2s linear;
}

.attendeeProfileContainer.hidden {
  display: none;
}

.attendeeProfilePhoto {
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.attendeeProfileContainer .attendeeProfilePhoto {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border-radius: 20px;
}

.attendeeProfilePhoto img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.attendeeProfileDetails {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ticketQuantityDisplay {
  display: flex;
  flex-direction: row;
  min-width: 50%;
  box-sizing: border-box;
  margin-bottom: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.ticketQuantityDisplay .ticketQuantityIconsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.ticketQuantityDisplay .ticketQuantityIcon {
  font-family: 'Basier Square', sans-serif;
  font-size: 14px;
  color: rgba(var(--secondary-colour), 1);
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  margin: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--secondary-colour), 0.1);
  border: 2px transparent solid;
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.2s linear;
  text-align: center;
  position: relative;
}

.ticketQuantityDisplay .ticketQuantityIcon:first-child {
  margin-left: 0px;
}

.ticketQuantityDisplay .ticketQuantityIcon:last-child {
  margin-right: 0px;
}

.ticketQuantityDisplay .ticketQuantityIcon.completed {
  color: rgba(var(--system-colour), 1);
  background-color: rgba(var(--secondary-colour), 0.2);
}

.ticketQuantityDisplay .ticketQuantityIcon.active {
  color: rgba(var(--system-colour), 1);
  border: 2px rgba(var(--system-colour), 1) solid;
}

.formInstructionText {
  margin: 20px 0px;
  padding: 0px 20px;
}

.formInstructionText h4 {
  color: rgba(var(--primary-colour), 1);
}

.checkoutTicketType.selected {
  flex-shrink: 0;
}

.feedbackContainer {
  height: 100%;
  padding: 0px;
}

.feedbackRatingContainer {
  margin: auto;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: fit-content;
  padding: 20px;
  flex-shrink: 0;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  transition: all 0.2s linear;
}

.feedbackRatingContainer.hidden {
  display: none;
  opacity: 0;
}

.feedbackRatingContainer .feedbackRatingOption {
  display: flex;
  flex-direction: column;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  justify-content: center;
  transition: all 0.2s linear;
  box-sizing: border-box;
  cursor: pointer;
  background: rgba(var(--secondary-colour), 0.1);
  border: 2px rgba(var(--secondary-colour), 0) solid;
}

.feedbackRatingContainer .feedbackRatingOption>* {
  text-align: center;
  transition: all 0.2s linear;
}

.feedbackContainer input {
  display: none;
}

.feedbackContainer label {
  cursor: pointer;
}

.feedbackRatingContainer .feedbackRatingOption:hover {
  background: rgba(var(--secondary-colour), 0.2);
  border: 2px rgba(var(--secondary-colour), 1) solid;
}

.feedbackRatingContainer .feedbackRatingOption.selected {
  background: rgba(var(--secondary-colour), 0.2);
  border: 2px rgba(var(--system-colour), 1) solid;
}

.feedbackRatingContainer .feedbackRatingOption.selected>* {
  color: rgba(var(--system-colour), 1);
}

.feedbackRatingContainer .feedbackRatingOption.muted {
  filter: blur(1px);
  opacity: 0.5;
}

.feedbackRatingContainer .feedbackRatingOption.muted:hover {
  filter: blur(0px);
}

.feedbackResponseContainer {
  box-sizing: border-box;
  padding: 0px 20px;
  margin: 10px 0px;
  transition: all 0.2s linear;
}

.feedbackResponseContainer.hidden {
  display: none;
  opacity: 0;
}

.feedbackResponseContainer .feedbackResponseText span {
  color: rgba(var(--system-colour), 1);
  transition: all 0.2s linear;
}

.feedbackResponseContainer .feedbackResponseText span.hidden {
  display: none;
  opacity: 0;
}

.buttonProceed.hidden {
  display: none;
  opacity: 0;
}

.feedbackResponseContainer {
  display: flex;
  flex-direction: row;
  margin: 20px 0px;
  flex-wrap: wrap;
  align-items: center;
}

.feedbackResponseContainer .feedbackResponseText {
  width: 100%;
  flex-shrink: 0;
  flex-basis: 100%;
}

.feedbackResponseContainer label {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  margin-right: 40px;
  align-items: center;
}

.feedbackResponseContainer .bulletCheck {
  margin-right: 20px;
}

.appCheckoutContainer.final {
  overflow-x: visible;
}

.appCheckoutContainer.final .cardNavigationControls {
  opacity: 0;
}

.appCheckoutCard.final {
  height: 100%;
  background-color: transparent;
  padding: 0px;
  /* overflow: visible; */
}

.TicketPurchasedSliderWrapper {
  width: 100%;
  padding-bottom: 100px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.TicketPurchasedSliderWrapper::-webkit-scrollbar {
  display: none;
}

.ticketsPurchasedSlider {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ticketsPurchasedSlider::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 700px) {
  div#wrapper .content {
    max-width: 1200px;
    box-sizing: border-box;
    margin: auto;
  }
}

.ticketPurchased {
  width: 100%;
  max-width: 350px;
  background-color: rgba(var(--secondary-colour), 1);
  box-sizing: border-box;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.ticketPurchased .attendeeDetails {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ticketPurchased .attendeeAvatar {
  width: 40px;
  height: 40px;
  aspect-ratio: 1/1;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 25px;
  overflow: hidden;
  border: solid 3px rgba(var(--background-colour), 1);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticketPurchased .attendeeAvatar img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.ticketPurchased .attendeeDetails .attendeeName>* {
  margin: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  font-weight: 500;
}

.ticketPurchased .attendeeName {
  width: 100%;
  flex-basis: 100%;
  font-size: 20px;
}

.ticketPurchased .attendeeName>* {
  min-height: 20px;
  color: rgba(var(--background-colour), 1);
}

.ticketPurchased .ticketQRCodeContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  margin: 30px 0px;
}

.ticketPurchased .ticketQRCodeContainer img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100% !important;
}

.ticketPurchased hr {
  margin: 0px;
  border-color: rgba(var(--background-colour), 0.2);
}

.ticketPurchased .ticketInformationContainer {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ticketPurchased h3 {
  font-size: 14px;
}

.ticketPurchased .ticketEventInformation>* {
  color: rgba(var(--background-colour), 1);
  min-height: 20px;
  font-weight: 500;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.ticketPurchased .ticketInformationContainer .ticketInformation:not(:last-child) {
  margin-bottom: 10px;
}

.ticketPurchased .ticketInformationContainer .ticketInformation.fullWidth {
  width: 100%;
  flex-basis: 100%;
}

.ticketPurchased .ticketInformationContainer .ticketInformation.halfWidth {
  width: calc(50% - 5px);
}

.ticketPurchased .ticketInformationContainer .ticketInformation h4 {
  color: rgba(var(--primary-colour), 1);
}

.ticketPurchased .ticketInformationContainer .ticketInformation h3 {
  color: rgba(var(--system-colour), 1);
}

.containerSelectedTicketButtons {
  display: flex;
  margin-top: 20px;
}

.buttonAddToWallet {
  background-color: black;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.buttonAddToWallet img {
  height: 50px;
}

.buttonSendAsEmail {
  background: rgba(var(--secondary-colour), 1);
  margin-top: 0px;
}

.buttonSendAsEmail svg#iconSend {
  width: 10px;
  height: 10px;
  fill: rgba(var(--background-colour), 1);
  margin-right: 2px;
}

.buttonSendAsEmail p {
  color: rgba(var(--background-colour), 1);
  filter: none;
  -webkit-filter: none;
}

.ticketPurchased .ticketBranding h4 {
  color: rgba(var(--secondary-colour), 0.5);
  text-align: center;
  margin-top: 30px;
  transition: all 0.2s linear;
}

.ticketPurchased .ticketBranding #pinSolid {
  display: inline;
  width: 16px;
  height: auto;
  fill: rgba(var(--secondary-colour), 0.8);
  margin-left: 5px;
  margin-right: 5px;
}

.ticketPurchased .ticketBranding h4 strong {
  color: rgba(var(--secondary-colour), 0.8);
  font-weight: normal;
}

.ticketPurchased .ticketBranding a {
  cursor: pointer;
}

.ticketPurchased .ticketBranding a:hover strong {
  color: rgba(var(--system-colour), 1);
}

.ticketPurchased .ticketBranding a:hover #pinSolid {
  fill: rgba(var(--system-colour), 1);
}

/* New Talent Stylings */

.eventLineupContainer .LineupItemWrapper {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 1px 0px;
  gap: 16px;
}

.eventLineupContainer .LineupItemWrapper .featuringObject img {
  border-radius: 25px;
  width: 40px;
  height: 40px;
}

.featuringObject {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  transition: width 0.2s linear;
  margin-right: 10px;
  gap: 16px;
}

.featuringObject .info {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  box-sizing: border-box;
}

.featuringObject .info .name {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.featuringObject .info .subtitle {
  color: rgba(var(--primary-colour), 1);
}

.featuringObject .available-platform-icons-container {
  display: flex;
  gap: 16px;
  min-height: 24px;
  transition: all 0.2s linear;
}

.featuringObject .available-platform-icon {
  height: 24px;
  max-width: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.2s linear;
}

.featuringObject .available-platform-icon svg {
  height: 24px;
  width: auto;
}

.featuringObject .available-platform-icon svg * {
  fill: rgba(var(--secondary-colour), 1);
  transition: all 0.2s linear;
}

.featuringObject .available-platform-icon:hover svg * {
  fill: rgba(var(--system-colour), 1);
}

.featuringObject .featuringObjectImage {
  flex-shrink: 0;
  border: 2px solid rgba(var(--secondary-colour), 1);
  border-radius: 32px;
  box-sizing: border-box;
  padding: 4px;
  height: 52px;
  width: 52px;
  transition: all 0.2s linear;
}

/*
.featuringObject:hover .featuringObjectImage {
  border: 2px solid rgba(var(--system-colour), 1);
}
  */

.featuringObject .featuringObjectImage {
  border-radius: 100%;
  background: #00000020;
}

form input,
form input::placeholder {
  font-size: 16px;
}

/* Check Promo Code Form Styling */

.eventPromoCodeCheck {
  padding-top: 20px;
  padding-bottom: 20px;
}

form.formPromoCodeCheck {
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

form.formPromoCodeCheck .form-row {
  cursor: text;
  width: 100%;
  flex-basis: 100%;
}

form.formPromoCodeCheck input {
  cursor: text;
}

form.formPromoCodeCheck .form-field input {
  letter-spacing: 0em;
}

.formPromoCodeCheck .form-field #iconPromoCodeCheckSubmit {
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.2s linear;
  fill: rgba(var(--secondary-colour), 1);
  opacity: 0;
  display: inline-block;
  margin-right: 0px;
}

.formPromoCodeCheck #iconPromoCodeCheckSubmit:hover {
  opacity: 1;
  fill: rgba(var(--system-colour), 1);
}

.formPromoCodeCheck .form-field:focus-within #iconPromoCodeCheckSubmit,
.formPromoCodeCheck .form-field.isNotEmpty #iconPromoCodeCheckSubmit {
  opacity: 1;
}

@media screen and (min-width: 1025px) {
  .appLandingContainer {
    gap: 30px;
  }

  .desktopLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .desktopRight {
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    overflow-y: scroll;
  }

  .eventTitleContainer {
    min-height: 25vh;
    text-align: left;
  }

  .eventTitleContainer h1 {
    font-size: 3em;
  }

  .eventTitleContainer h3 {
    font-size: 1.5em;
  }

  .appCheckout {
    position: relative;
  }
}

/* Discounted Ticket Styles */

.checkoutTicketType.discounted .ticketPrice .ticketCurrency.discounted {
  margin-left: 10px;
}

.checkoutTicketType.discounted .ticketPrice span:not(.ticketFees, .discounted) {
  text-decoration: line-through;
  opacity: 0.8;
}

.ticketOrderSummaryRow.discount>* {
  color: rgba(var(--system-colour), 1);
}

/* Sold Out Tickets Styles */

.checkoutTicketType.isUnavailable {
  cursor: not-allowed;
  background-color: rgba(var(--secondary-colour), 0.05);
}

.checkoutTicketType.isUnavailable:hover {
  border: 2px transparent solid;
  background-color: rgba(var(--secondary-colour), 0.05);
}

.checkoutTicketType .isUnavailableMessage {
  font-size: 14px;
  display: none;
  color: rgba(var(--secondary-colour));
}

.checkoutTicketType.isUnavailable .ticketIcon {
  width: fit-content;
  height: 20px;
  display: flex;
  align-items: center;
}

.checkoutTicketType.isUnavailable .isUnavailableMessage {
  display: block;
}

.checkoutTicketType.isUnavailable svg {
  display: none;
}

.checkoutTicketType.isUnavailable .ticketName h2 {
  color: rgba(var(--secondary-colour), 1);
}

.checkoutTicketType.isUnavailable .ticketPrice h3 {
  color: rgba(var(--secondary-colour), 1) !important;
}

.checkoutTicketType.isUnavailable .ticketNote {
  display: none;
}

/* New QR Code Styles */

.buttonAddToWallet {
  width: 100%;
}

.ticketPurchased .ticketQRCodeContainer>img {
  width: 100%;
}

.ticketPurchased .ticketQRCodeContainer .ticketQRCodeText {
  margin: 0px 20px;
  text-align: center;
}

.ticketPurchased .ticketQRCodeContainer .ticketQRCodeText>* {
  margin: 0px;
}

@media screen and (min-width: 1025px) {
  .appLandingContainer {
    flex-direction: row;
  }

  .eventTitle {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    min-height: 30vh;
  }

  .desktopLeft {
    order: 1;
    width: 50%;
    height: calc(100%);
  }

  .desktopRight {
    order: 2;
    width: 50%;
    height: calc(100%);
    overflow-y: scroll;
  }

  .eventOverview h3 {
    font-size: 1em;
    font-weight: 500;
  }

  .eventOverview h1 {
    font-size: 3em;
    font-weight: 500;
  }

  .desktopContainer .artworkHeroContainer {
    padding: 40px;
  }

  .desktopContainer .artworkHero {
    border-radius: 20px;
  }

  .desktopContainer .artworkBlur {
    border-radius: 20px;
  }

  .appCheckoutContainer {
    backdrop-filter: none;
    justify-content: flex-start;
  }

  .ticketsPurchasedSlider {
    justify-content: initial;
    align-items: flex-start;
  }

  .ticketPurchased {
    max-width: initial;
    width: calc(50% - 10px);
  }
}

.questionWrapper {
  cursor: pointer;
  flex-grow: 1;
  margin-bottom: 16px;
}

.date-picker-wrapper {
  position: absolute;
  top: 74px;
  left: 0;
  width: 80%;
  height: 500px;
  z-index: 10;
  background: rgba(var(--background-colour), 0.95);
  border: 4px solid rgba(var(--secondary-colour), 0.2);
  border-radius: 15px;
  padding: 8px;
}

.date-picker-wrapper .react-calendar__navigation__label__labelText {
  color: rgba(var(--primary-colour), 1);
}

.radioRow {
  background: rgba(var(--secondary-colour), 0.1);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  width: 100%;
  display: flex;
}

.radioRow .bulletCheck {
  margin-right: 20px;
  flex-shrink: 0;
}

.radioLabel {
  font-family: 'Basier Square', sans-serif;
  flex-basis: 100%;
  color: rgba(var(--secondary-colour), 1);
  cursor: pointer;
  font-size: 14px;
}

/* Event Series Calendar Selector */

.react-calendar {
  width: 100%;
  height: 100%;
  background: none;
  font-family: 'Basier Square', sans-serif;
  line-height: 1.125em;
  color: rgba(var(--system-colour), 1);
}

.react-calendar__viewContainer {
  display: flex;
}

.react-calendar__viewContainer>* {
  margin-top: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  font-size: 16px;
  font-weight: 500;
  color: rgba(var(--system-colour), 0);
  min-width: 34px;
  background: none;
  aspect-ratio: 2/1;
  flex-grow: 0;
  height: 100%;
  width: fit-content;
}

.react-calendar__navigation button:enabled {
  color: rgba(var(--system-colour), 1);
}

.react-calendar__navigation button.react-calendar__navigation__next-button:enabled,
.react-calendar__navigation button.react-calendar__navigation__prev-button:enabled {
  background: rgba(var(--secondary-colour), 0.1);
  border-radius: 15px;
}

.react-calendar__navigation button.react-calendar__navigation__next-button:enabled:hover,
.react-calendar__navigation button.react-calendar__navigation__prev-button:enabled:hover {
  background: rgba(var(--secondary-colour), 0.5);
  border-radius: 15px;
}

.react-calendar__year-view__months {
  margin-left: 1rem;
  gap: 1rem;
}

.react-calendar__year-view__months__month {
  aspect-ratio: 2 / 1 !important;
  flex-basis: calc(33.3333% - 40px) !important;
}

.react-calendar__month-view {
  width: 100%;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days {
  justify-content: space-between;
  gap: calc(14.2857% - 40px);
  margin-bottom: 8px;
}

.react-calendar__month-view__days__day {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1 !important;
  flex-basis: calc(14.2857% - 40px) !important;
  margin: 16px 0px !important;
  transition: all 0.2s linear;
}

.react-calendar__tile:enabled:hover {
  border-color: rgba(var(--secondary-colour), 1);
}

.react-calendar__tile:enabled:hover abbr {
  color: rgba(var(--system-colour), 1);
}

.react-calendar__tile--active {
  border-color: rgba(var(--system-colour), 1) !important;
}

.react-calendar__tile--active abbr {
  color: rgba(var(--system-colour), 1) !important;
}

.react-calendar__month-view__days__day abbr {
  font-size: 14px;
  transition: all 0.2s linear;
}

.react-calendar__month-view__weekdays {
  justify-content: space-between;
}

.react-calendar__month-view__weekdays__weekday {
  width: 40px !important;
  max-width: 40px !important;
  max-width: inherit;
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  color: rgba(var(--primary-colour), 1);
  text-decoration: none;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-weight: bold;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  border-radius: 20px !important;
}

.react-calendar__tile {
  aspect-ratio: 1 / 1;
  max-width: 100%;
  background: none;
  text-align: center;
  font-size: 14px;
  aspect-ratio: 1/1;
  width: 40px !important;
  min-width: 40px !important;
  height: 40px !important;
  min-height: 40px !important;
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
  font: inherit;
  color: rgba(var(--secondary-colour), 0.5);
  flex-basis: inherit;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: rgb(var(--background-colour));
}

.react-calendar__tile:enabled {
  border-radius: 20px;
  background: rgba(var(--secondary-colour), 0.1);
  border: 4px solid rgba(var(--secondary-colour), 0.2);
  color: rgba(var(--secondary-colour), 1);
  transition: all 0.2s linear;
  font-weight: 500;
}

.react-calendar__tile--now {
  color: rgba(var(--detail-colour), 0.5);
}

@media (min-width: 768px) {
  .react-calendar {
    max-width: 500px;
    margin: 0 auto;
  }
}

@media (min-width: 1024px) {
  .react-calendar {
    max-width: 500px;
    margin: 0 auto;
  }
}

/* Event Series List Selector */

.checkoutEventListItem {
  font-family: 'Basier Square', sans-serif;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  border: 2px transparent solid;
  background-color: rgba(var(--secondary-colour), 0.1);
  border-radius: 16px;
  align-items: center;
  transition: all 0.2s linear;
  cursor: pointer;
  overflow: hidden;
  color: rgba(var(--system-colour), 1);
}

.checkoutEventListItem:hover {
  background-color: rgba(var(--secondary-colour), 0.2);
  border: 2px rgba(var(--secondary-colour), 1) solid;
}

.checkoutEventListItem.selected {
  background-color: rgba(var(--secondary-colour), 0.2);
  border: 2px rgba(var(--system-colour), 1) solid;
}

.checkoutEventListItem .eventInformation {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  align-items: center;
}

.checkoutEventListItem .eventDescription {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  font-size: 14px;
}

.checkoutEventListItem .eventDay {
  flex-basis: 100%;
  font-weight: 500;
  font-size: 14px;
}

.checkoutEventListItem .eventDate {
  flex-shrink: 0;
  color: rgba(var(--secondary-colour), 1);
}

.checkoutEventListItem .eventTime {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkoutEventListItem.isUnavailable {
  cursor: not-allowed;
  background-color: rgba(var(--secondary-colour), 0.05);
}

.checkoutEventListItem.isUnavailable:hover {
  border: 2px transparent solid;
  background-color: rgba(var(--secondary-colour), 0.05);
}

.checkoutEventListItem .isUnavailableMessage {
  display: none;
}

.checkoutEventListItem.isUnavailable .isUnavailableMessage {
  font-size: 14px;
  display: block;
  color: rgba(var(--secondary-colour));
  white-space: nowrap;
}

.timeslotsContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
}

.timeslotsContainer .checkoutEventListItem {
  margin: 0px;
  transition: all 0.2s linear;
}

.timeslotsContainer .checkoutEventListItem .eventTime {
  font-size: 14px !important;
  color: rgba(var(--secondary-colour), 1);
  transition: all 0.2s linear;
}

.checkoutEventListItem.selected .eventTime {
  color: rgba(var(--system-colour), 1);
}

.timeslotUnavailable,
.timeslotUnavailable:hover {
  cursor: not-allowed;
  border: none;
  background-color: rgba(var(--secondary-colour), 0.1);
  opacity: 0.5;
}

.checkoutEventListItem .calendar-icon {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background: linear-gradient(#db5858, #a44343);
  box-sizing: border-box;
  padding: 4px;
  background-color: #fff;
  color: #000;
  gap: 4px;
  overflow: hidden;
  flex-shrink: 0;
}

.checkoutEventListItem .calendar-icon-inner {
  background: white;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  line-height: 16px;
}

.checkoutEventListItem.isUnavailable .calendar-icon {
  display: none;
}

.checkoutEventListItem.isUnavailable .calendar-icon-inner {
  display: none;
}

.checkoutEventListItem .month {
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
}

/* Carousel gallery styles */

.embla {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* max-width: 34rem; */
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 0px;
  --slide-size: 100%;
  border-radius: 24px;
}

.embla.thumbnails_active {
  display: none;
}

.embla.fullscreen_active {
  z-index: 10;
}

.embla__full_screen {
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.fullscreen {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: auto;
}

.fullscreen.fullscreen_active {
  pointer-events: none;
}

.embla__viewport {
  aspect-ratio: 1/1;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.embla__container {
  display: flex;
  position: relative;
  aspect-ratio: 1/1;
  height: 100%;
  touch-action: pan-y pinch-zoom;
  gap: 16px;
  align-content: center;
  /* margin-left: -4px; */
  /* margin-left: calc(var(--slide-spacing) * -1); */
}

.fullscreen_active .embla__container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.thumbnails_active .embla__container {
  display: none;
}

.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}

.fullscreen_active .embla__slide.is-snapped {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
}

.embla__slide__img {
  z-index: 13;
  border-radius: 16px;
  display: block;
  height: var(--slide-height);
  /* width: 99%; */
  width: 100%;
  object-fit: cover;
}

.embla__slide__video {
  z-index: 13;
  border-radius: 16px;
  display: block;
  height: 100%;
  /* height: var(--slide-height); */
  /* width: 99%; */
  width: 100%;
  object-fit: cover;
}

.embla__controls {
  width: 100%;
  margin-top: 16px;
}

.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
}

.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.embla__button:disabled {
  color: var(--detail-high-contrast);
}

.embla__button__svg {
  width: 35%;
  height: 35%;
}

.embla__dots {
  position: absolute;
  width: fit-content;
  right: 17px;
  bottom: 17px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.fullscreen_active .embla__dots {
  display: none;
}

.thumbnails_active .embla__dots {
  display: none;
}

.embla__dot {
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(255, 255, 255, 0.2);
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  width: 6px;
  height: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.embla__dot--selected {
  background-color: white;
}

.embla__slide__img {
  user-select: none;
}

.carouselButtonsWrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.fullscreen_active .carouselButtonsWrapper {
  display: none;
}

.thumbnails_active .carouselButtonsWrapper {
  display: none;
}


.carouselButton {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  background: #1a1a1a80;
  box-sizing: border-box;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(16px);
}

.thumbnailButton {
  left: 12px;
  top: 12px;
  cursor: pointer;
}

.fullscreenButton {
  position: fixed;
  pointer-events: all;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  background: #1a1a1a80;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
}

.slideCounter {
  left: 50px;
  top: 12px;
  min-height: 34px;
  color: white;
  padding: 8px 12px;
}

.slideCounterTextWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
}

.enterFullscreenButton {
  right: 12px;
  top: 12px;
  cursor: pointer;
}

.exitFullscreenButton {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 12;
  cursor: pointer;
}

.fullScreenButton svg {
  color: white;
}

.muteUnmuteButton {
  left: 12px;
  bottom: 12px;
  cursor: pointer;
}

.thumbnails {
  display: none;
}

.thumbnails.thumbnails_active {
  position: relative;
  z-index: 12;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 8px 8px;
  grid-template-areas:
    ". . ."
    ". . ."
    ". . .";
}

.thumbnail {
  will-change: transform;
  cursor: pointer;
  aspect-ratio: 1 / 1;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 24px;
  box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.25);
}

.thumbnail>* {
  will-change: transform;
  transform: scale(1);
  transition: transform 0.5s linear;
}

.thumbnail:hover>* {
  transform: scale(1.1);
}

.thumbnail.selected {
  animation: zoom-out 0.5s both;
  z-index: 10;
}

@keyframes zoom-out {
  0% {
    transform: scale(3);
  }

  100% {
    transform: scale(1);
    transform-origin: 50% 50%;
  }
}

.thumbnail.top {
  transform-origin: 50% 0%;
}

.thumbnail.bottom {
  transform-origin: 50% 100%;
}

.thumbnail.left {
  transform-origin: 0% 50%;
}

.thumbnail.right {
  transform-origin: 100% 50%;
}

.thumbnail.top.left {
  transform-origin: 0% 0%;
}

.thumbnail.top.center {
  transform-origin: 50% 0%;
}

.thumbnail.top.right {
  transform-origin: 100% 0%;
}

.thumbnail.middle.left {
  transform-origin: 0% 50%;
}

.thumbnail.middle.center {
  transform-origin: 50% 50%;
}

.thumbnail.middle.right {
  transform-origin: 100% 50%;
}

.thumbnail.bottom.left {
  transform-origin: 0% 100%;
}

.thumbnail.bottom.center {
  transform-origin: 50% 100%;
}

.thumbnail.bottom.right {
  transform-origin: 100% 100%;
}


.thumbnail_video {
  border-radius: 16px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1 / 1;
}

.thumbnail .playVideoIconWrapper {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.thumbnail .playVideoIcon {
  position: relative;
  opacity: 0.7;
  width: 40%;
  height: 40%;
  margin: auto;
}

.fullscreen_active {
  display: flex;
  z-index: 11;
  align-items: center;
  justify-content: center;
}

.background.fullscreen_active {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.react-transform-component {
  width: 100% !important;
  height: 100% !important;
}

.embla:not(.fullscreen_active) .react-transform-component {
  transform: translate(0, 0) !important;
}

.embla.fullscreen_active .react-transform-wrapper {
  background: rgba(0, 0, 0, 0.96);
  backdrop-filter: blur(40px);
}

.embla.fullscreen_active .embla__slide__video,
.embla.fullscreen_active .embla__slide__img {
  border-radius: 0px !important;
}

.artworkHero .embla.fullscreen_active img,
.artworkHero .embla.fullscreen_active video {
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fullscreen_active .embla__slide {
  padding: 0px;
  margin: 0px;
}

.fullscreen_active .embla__container {
  position: fixed;
  left: 0;
  top: 0;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}

.fullscreen_active .playVideoIconWrapper {
  display: flex;
  position: absolute;
  z-index: 13;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* click handler is on the video */
}

.fullscreen_active .playVideoIcon {
  position: relative;
  opacity: 0.7;
  width: 30%;
  height: 30%;
  margin: auto;
}


/* END Carousel gallery styles */