.errorFallbackWrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
}

.errorFallbackMessage {
  flex: 1;
  justify-content: 'center';
  text-align: center;
  padding: 1rem;
  font-family: 'Basier Square', sans-serif;
  font-size: 24px;
  color: wheat;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.errorButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: fit-content;
  margin-top: 10px;
  background-color: rgba(23, 23, 23, 0.1);
  box-sizing: border-box;
  padding: 20px 50px;
  overflow: hidden;
  border-radius: 10px;
  filter: blur(0px);
  opacity: 1;
  transition: all 0.2s linear;
  cursor: pointer;
}

.errorButton p {
  font-size: 14px;
  text-align: center;
  color: wheat;
  /* color: rgba(23,23,23, 1); */
}

.errorButton:hover {
  /* color: rgba(23,23,23, 1); */
  background-color: rgba(23, 23, 23, 0.5);
}
.errorButton:hover p {
  color: whitesmoke;
  /* color: rgb(0,0,0); */
}

.errorButton:active {
  opacity: 0.8;
}
